import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import FeatureGrid from "components/ui/base/FeatureGrid";
import TestimonialWithImageIrene from "components/ui/extended/TestimonialWithImageIrene";
import ImageWithDescription from "components/ui/base/ImageWithDescription";
import PlainHeaderWithCarousel from "components/ui/base/PlainHeaderWithCarousel";
import RegisterForCustomerService from "components/ui/extended/RegisterForCustomerService";
import Customers from "components/ui/extended/Customers";
import { ACCENT_COLOR } from "constants/code";
import Code from "components/ui/base/Code";

interface IndexPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
      };
    };
  };
  location: Location;
}

export default ({ data, location }: IndexPageProps) => {
  const { t } = useTranslation();

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.live-chat-customization.seo.title")}
        description={t("pages.live-chat-customization.seo.description")}
        image={{
          relativePath: "meta/live-chat-customization.jpg",
          alt: "Live Chat Customization",
        }}
      />

      <PlainHeaderWithCarousel
        title={t("pages.live-chat-customization.title")}
        description={t("pages.live-chat-customization.description")}
        ctaRegister={{ show: true }}
        titleMaxWidth={700}
        descriptionMaxWidth={500}
        images={[
          {
            relativePath:
              "pages/live-chat-customization/live-chat-custom-1.jpg",
            alt: "Customerly",
          },
          {
            relativePath:
              "pages/live-chat-customization/live-chat-custom-2.jpg",
            alt: "Customerly",
          },
          {
            relativePath:
              "pages/live-chat-customization/live-chat-custom-3.jpg",
            alt: "Customerly",
          },
          {
            relativePath:
              "pages/live-chat-customization/live-chat-custom-4.jpg",
            alt: "Customerly",
          },
          {
            relativePath:
              "pages/live-chat-customization/live-chat-custom-5.jpg",
            alt: "Customerly",
          },
          {
            relativePath:
              "pages/live-chat-customization/live-chat-custom-6.jpg",
            alt: "Customerly",
          },
          {
            relativePath:
              "pages/live-chat-customization/live-chat-custom-7.jpg",
            alt: "Customerly",
          },
          {
            relativePath:
              "pages/live-chat-customization/live-chat-custom-8.jpg",
            alt: "Customerly",
          },
          {
            relativePath:
              "pages/live-chat-customization/live-chat-custom-9.jpg",
            alt: "Customerly",
          },
        ]}
        breadcrumbs={[
          {
            name: "Customerly",
            path: "/",
          },
          {
            name: "Features",
            path: "/features/",
          },
          {
            name: "Live Chat Customization",
            path: "/live-chat-customization/",
          },
        ]}
      />

      <ImageWithDescription
        id={"brand-matching"}
        title={t("pages.live-chat-customization.1.title")}
        description={t("pages.live-chat-customization.1.subtitle")}
        image={{
          relativePath:
            "pages/live-chat-customization/customize-live-chat-look-and-feel.jpg",
          alt: t("pages.live-chat-customization.1.title"),
          position: "left",
          fullWidth: true,
        }}
      />

      <ImageWithDescription
        id={"chat-icons"}
        title={t("pages.live-chat-customization.2.title")}
        description={t("pages.live-chat-customization.2.subtitle")}
        image={{
          relativePath:
            "pages/live-chat-customization/customize-live-chat-custom-icons.jpg",
          alt: t("pages.live-chat-customization.2.title"),
          position: "right",
          fullWidth: true,
        }}
      />

      <ImageWithDescription
        id={"agent-profiles"}
        title={t("pages.live-chat-customization.3.title")}
        description={t("pages.live-chat-customization.3.subtitle")}
        image={{
          relativePath:
            "pages/live-chat-customization/customize-live-chat-profile.jpg",
          alt: t("pages.live-chat-customization.3.title"),
          position: "left",
          fullWidth: true,
        }}
      />

      <ImageWithDescription
        id={"company-intro"}
        title={t("pages.live-chat-customization.4.title")}
        description={t("pages.live-chat-customization.4.subtitle")}
        image={{
          relativePath:
            "pages/live-chat-customization/customize-live-chat-mission.jpg",
          alt: t("pages.live-chat-customization.4.title"),
          position: "right",
          fullWidth: true,
        }}
      />

      <ImageWithDescription
        id={"chat-languages"}
        title={t("pages.live-chat-customization.5.title")}
        description={t("pages.live-chat-customization.5.subtitle")}
        image={{
          relativePath:
            "pages/live-chat-customization/customize-live-chat-languages.jpg",
          alt: t("pages.live-chat-customization.5.title"),
          position: "left",
          fullWidth: true,
        }}
      />

      <Code
        title={t("pages.live-chat-customization.code.title")}
        description={t("pages.live-chat-customization.code.subtitle")}
        centered={true}
        button={{
          text: t("pages.live-chat-customization.code.cta"),
          path: "/live-chat-api/",
        }}
      >
        {ACCENT_COLOR}
      </Code>

      <FeatureGrid
        title={t("pages.live-chat-customization.others.title")}
        centered={false}
        sections={[
          {
            title: t("pages.live-chat-customization.others.helpCenter.title"),
            description: t(
              "pages.live-chat-customization.others.helpCenter.description"
            ),
            image: {
              alt: "Help Center",
              relativePath: "icons/help-center-software.png",
            },
            links: [
              {
                text: t("pages.live-chat-customization.others.helpCenter.cta"),
                path: "/help-center/",
              },
            ],
          },
          {
            title: t("pages.live-chat-customization.others.surveys.title"),
            description: t(
              "pages.live-chat-customization.others.surveys.description"
            ),
            image: {
              alt: "Surveys",
              relativePath: "icons/surveys.png",
            },
            links: [
              {
                text: t("pages.live-chat-customization.others.surveys.cta"),
                path: "/surveys/",
              },
            ],
          },
          {
            title: t("pages.live-chat-customization.others.funnels.title"),
            description: t(
              "pages.live-chat-customization.others.funnels.description"
            ),
            image: {
              alt: "Funnels",
              relativePath: "icons/funnel-marketing.png",
            },
            links: [
              {
                text: t("pages.live-chat-customization.others.funnels.cta"),
                path: "/marketing-funnel/",
              },
            ],
          },
        ]}
      />

      <TestimonialWithImageIrene />

      <RegisterForCustomerService />

      <Customers />
    </GlobalWrapper>
  );
};

export const indexPageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
